import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/undraw_Work_time_re_hdyv.svg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img src={dev} alt="Agencija Za Hemikalije, eksperti u upravljanju hemikalijama i biocidima." />
        </Thumbnail>
        <Details theme={theme}>
          <h1>Više O Nama</h1>
          <p>
            Više od 13 godina uspešno pomažemo velikim, srednjim kao i malim kompanijama da u skladu zakonom upravljaju
            hemikalijama i biocidima. Bez obzira da li su interne ili komercijalne potrebe u pitanju, u svakom momentu
            znaćete šta se od vas zakonski očekuje.
          </p>
          <Button as={AnchorLink} href="#contact">
            Zakažite Besplatne Konsultacije
          </Button>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
