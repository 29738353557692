import React from 'react';
import { Container } from 'components/common';
import contact from 'assets/illustrations/undraw_Goals_re_lu76.svg';
import { Wrapper, Details, Thumbnail } from './styles';
import ContactForm from './ContactForm';

export const Contact = () => (
  <Wrapper as={Container} id="contact">
    <Details>
      <ContactForm />
      <address>
        Agencija za konsalting u hemijskoj industriji
        <br />
        Vojvode Stepe 411G, Voždovac, Beograd
      </address>
      <p>
        <a href="tel:+381 (66) 630 6782">tel. +381 (66) 630 6782</a>
      </p>
    </Details>
    <Thumbnail>
      <img src={contact} alt="Ja sam Ivana Nikolov. Savetnik ekspert u domenu upravljanja hemikalijama." />
    </Thumbnail>
  </Wrapper>
);
