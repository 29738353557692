import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Card } from 'components/common';
import { Wrapper, Grid, Item, Content } from './styles';

export const Services = () => {
  const { theme } = useContext(ThemeContext);
  const edges = [
    {
      name: 'Konsultacije',
      description: 'Konsultacije o regulativi pri uvozu hemikalija i biocida',
    },
    {
      name: 'Konsultacije',
      description: 'Konsultacije o izmenama regulative o hemikalijama i biocida',
    },

    {
      name: 'Konsultacije',
      description: 'Konsultacije o regulativi u oblasti hemikalija i biocidnih proizvoda',
    },

    {
      name: 'Izrada Dokumentacije',
      description: 'Izrada dokumentacije (dosijea) o hemikaliji za upis u registar hemikalija',
    },

    {
      name: 'Konsultacije',
      description: 'Konsultacije o primeni odredbi o klasifikaciji, pakovanji, obeležavanju i oglašavanju hemikalija',
    },

    {
      name: 'Izrada Dokumentacije',
      description: 'Izrada bezbednosnog lista u pripremi stavljanja hemikalija u promet',
    },

    {
      name: 'Konsultacije',
      description: 'Konsultacije i direkcije o procedurama pri uvozu i izvozu opasnih hemikalija',
    },

    {
      name: 'Konsultacije',
      description:
        'Konsultacije o zabranama i ograničenjima pri proizvodnji, stavljanju u promet i korišćenju opasnih hemikalija',
    },

    {
      name: 'Izrada Dokumentacije',
      description: 'Priprema dokumentacije za dobijanje dozvola za promet i korišćenje opasnih hemikalija',
    },

    {
      name: 'Izrada Dokumentacije',
      description: 'Priprema dokumentacije o biocidnim proizvodima za upis u privremenu listu',
    },

    {
      name: 'Konsultacije',
      description: 'Konsultacije o regulativi i zakonima o biocidnim proizvodima',
    },

    {
      name: 'Zastupanje',
      description: 'Zastupanje u odnosima sa nadležnim inspekcijskim organima',
    },
  ];

  return (
    <Wrapper as={Container} id="services">
      <h2>Servisi</h2>
      <Grid>
        {edges.map(node => (
          <Item key={node.description} theme={theme}>
            <Card theme={theme}>
              <Content>
                <p>{node.description}</p>
                <h4>{node.name}</h4>
              </Content>
            </Card>
          </Item>
        ))}
      </Grid>
    </Wrapper>
  );
};
