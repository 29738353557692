import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 2rem 0;
`;

export const Grid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 8fr;
  gap: 1.2rem 1.2rem;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);
`;

export const Content = styled.div`
  padding: 1rem 0;
  min-height: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h4 {
    font-size: 1.125rem;
    color: #515151;
    opacity: 0.2;
    text-align: right;
    margin: 0;
  }

  p {
    font-size: 1.2rem;
    color: #707070;
  }
`;

export const Languages = styled.div`
  opacity: 0.5;
  font-size: 14px;
`;
